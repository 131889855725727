body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Set the width and color of the scroll bar */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar:hover {
  width: 10px;
  background-color: #fbfbfb;
}

/* Set the style of the thumb (the draggable part of the scroll bar) */
::-webkit-scrollbar-thumb {
  background-color: #04264c;
  border-radius: 2px;
}

/* Set the color of the thumb when hovering over it */
::-webkit-scrollbar-thumb:hover {
  background-color: #d33761;
}
